@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css");
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Russo One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root {
  height: 100%;
}
